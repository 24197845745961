.profile .opacity-disabled, .profile .opacity-disabled div[data-slot="input-wrapper"]{
    border-radius: 0.625rem;
    background: rgba(16, 193, 208, 0.11);
    color: var(--secondary);
}

.profile .opacity-disabled *{
    color: var(--secondary);
    opacity: 1
}
.profile .opacity-disabled [data-slot="trigger"], .profile .opacity-disabled div[data-slot="input-wrapper"]{
    border: none;
}

.profile .MuiInputBase-root.Mui-disabled{
    background: rgba(16, 193, 208, 0.11);
}

.profile .Mui-disabled{
    border-radius: 0.625rem;
    background: rgb(255 255 255 / 11%);
    color: var(--secondary);   
    border: none;
}
.profile .Mui-disabled fieldset{
    border: none;
}
.profile .Mui-disabled input {
    -webkit-text-fill-color: #07383d94
}

.profile div[data-slot="input-wrapper"]{
    background-color: var(--primary-light);
    border: 1px solid var(--primary)
}

