.table-container-main {
  width: 100%;
  height: 100%;
  overflow: auto;
  max-height: 45rem;
}
:root {
  --border-color: #dadada;
}

.calendar-table {
  width: 100%;
  border-collapse: collapse;
}
.calendar-table th {
  user-select: none;
  border: none;
  font-weight: 300;
}
.calendar-table td {
  border-color: var(--border-color);
  border-width: 1px;
  border-style: solid;
  border-top: 0;
  border-left: 0;
}

tbody:before {
  content: "@";
  display: block;
  line-height: 10px;
  text-indent: -99999px;
}
.calendar-table tr {
  position: relative;
}
.calendar-table .cell,
.calendar-table .extra {
  position: relative;
  max-height: 5rem;
  max-width: 13.125rem;
}

.calendar-table .extra {
  width: 3rem;
}
.calendar-table .cell {
  height: 5rem;
  width: 13.125rem;
}
.calendar-table .date-label {
  color: #9ca4a5;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}
.calendar-table .cell .slot-label {
  position: absolute;
  padding-left: 20px;
  top: -5px;
  font-size: 14px;
  color: #0a4e54;
}
.calendar-table .cell .g.empty .story {
  background-color: #ffffff00;
}
.calendar-table .cell .g.video .story {
  background-color: #e1fdff;
}
.calendar-table .cell .g.review .story {
  background-color: #ebfffa;
}
.calendar-table .cell .g .story {
  position: relative;
  width: 100%;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  padding: 5px 10px;
  height: 100%;
}

.g {
  cursor: pointer;
  width: 100%;
  height: 100%;
  position: relative;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
  background-image: -webkit-gradient(
    linear,
    left bottom,
    left top,
    color-stop(0, white),
    color-stop(0.67, var(--secondary))
  );
  background-image: -moz-linear-gradient(
    center bottom,
    rgb(14, 173, 173) 33%,
    rgb(0, 255, 255) 67%
  );
  padding: 1px;
}
.calendar-table th.current-date > .date {
  background: #015961;
  color: white;
  display: flex;
  height: 2.5rem;
  padding: 5px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0.5rem;
}
.calendar-table td.current-date {
  background-color: #f0fcfd;
}

.prev,
.next {
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}
.prev:hover,
.next:hover {
  box-shadow: 0px 0px 5px 0px var(--secondary);
}

.calendar-table thead,
.calendar-table thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
}

.calendar-table th {
  position: sticky;
  top: 50px;
  background: white;
}

.slot {
  border-radius: 0.625rem;
  border: 1px solid #cecece;
  background-color: #fff;
  display: flex;
  height: 3rem;
  user-select: none;
  cursor: pointer;
  padding: 0.625rem 1.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.625rem;
  flex-shrink: 0;
}
.slot.selected {
  background-color: var(--primary);
}
button {
  cursor: pointer;
}

.profile-card {
  width: 14.375rem;
  min-height: 16.875rem;
  height: fit-content;
  flex-shrink: 0;
  box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.25);
  border-radius: 0.6rem;
  top: -10px;
  left: -10px;
  padding: 10px;
}

ul[data-slot="list"] {
  padding: 0;
}

.react-tel-input .form-control {
  position: relative;
  width: 100%;
  padding-left: 80px;
  font-size: 14px;
  outline: none;
  border: 1px solid var(--primary);
  box-shadow: none;
  background-color: var(--primary-light);
  height: 45px;
  border-radius: 0.6125rem;
}
.react-tel-input .flag-dropdown {
  background-color: var(--primary-light);
  border: 1px solid var(--primary);
  width: 70px;
  display: flex;
  justify-content: center;
  border-top-left-radius: 0.6125rem;
  border-bottom-left-radius: 0.6125rem;
}
.react-tel-input .flag-dropdown .flag {
  scale: 1.5;
}

.react-tel-input .country-list {
  left: 10px;
  top: 40px;
}
.react-tel-input .selected-flag:hover {
  background-color: var(--primary-light);
}
.react-tel-input .flag-dropdown.open .selected-flag.open,
.react-tel-input .flag-dropdown.open .selected-flag.open:hover {
  background-color: var(--primary);
}
.react-tel-input .flag-dropdown.open {
  background-color: var(--primary);
}
.disabled-input div[data-slot="input-wrapper"] {
  background-color: #10c1d01a;
}

.skip-button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 52px;
  padding: 10px 37px 10px 37px;
  border-radius: 10px;
  font-size: 18px;
  color: #756c6c;
  border: 1px;
  gap: 10px;
  border: 1px solid #bbbbbb;
}

.invalid-border .flag-dropdown {
  border-color: red;
}

.bg-info{
  background: #FDFAF1;
}

.tab-list{
  overflow: auto;
}

#main-footer, .tab-list{
  background: var(--primary-light)
}

/*nav{*/
/*  background-color: #fff !important;*/
/*}*/

table thead th {
  font-size: 14px;
  line-height: 21px;
  font-weight: 500
}
table tr{
  height: 60px
}
/*table tr th, table tr td{*/
/*  padding-left: 2rem;*/
/*  padding-right: 2rem;*/
/*}*/
tbody:before{
  display: none
}
.primary-table tbody tr:nth-child(odd) {
  background-color: var(--primary-light-1);
}
.gray-table tbody tr:nth-child(odd) {
  background-color: #F8F8F8;
}
table tbody tr{
  padding-top: 10px;
  padding-bottom: 10px;
}

.window main{
  position: relative;
}
.window main .after{
  position: absolute;
  width: 99%;
  padding: 5px;
  top: 0;
  margin-bottom: 0.5%;
  margin-left: 0.5%;
  margin-right: 0.5%;
  height: 99.5%;
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
  background: white;
}

.live-time-track{
  position: absolute;
  z-index: 1;
  height: 1px;
  width: 90%;
  background: red;
  left: 7%;
}
.p-calendar {
  box-sizing: border-box;
  width: calc(100% - 20px);
}
.p-calendar .p-datepicker{
  box-sizing: border-box;
  margin: 10px;
}
.p-calendar .p-datepicker tbody td {
  padding: 5px;
}
.p-calendar .p-datepicker .p-disabled {
  color: lightgray;
  cursor: not-allowed;
}
.p-calendar .p-datepicker td span.p-highlight {
  background: var(--primary);
  height: 50px;
  border-radius: 6px;
}
.p-yearpicker-year.p-highlight{
  color: var(--primary);
  font-weight: 700;
}
.p-datepicker-header{
  margin-bottom: 20px;
}

.p-datepicker-today span{
  border: 1px solid var(--secondary);
  color: var(--secondary);
  border-radius: 6px !important;
  height: 50px !important;
}

.disabled-card-speciality{
  opacity: 0.5;
  cursor: not-allowed !important;
}

.slick-prev.slick-disabled:before, .slick-next.slick-disabled:before{
  display: none !important
}
.speciality-card{
  transition: 0.3s ease-in-out;
}

.speciality-card:hover{
  background-color: var(--primary) !important;
  color: white
}

.speciality-card:hover svg path{
  fill: white !important
}

.slick-track{
  margin-left: unset !important;
}
nav[aria-label="Breadcrumbs"] span[data-slot="separator"] svg {
  stroke: var(--primary)
}
nav[aria-label="Breadcrumbs"] span[data-slot="separator"] {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
nav[aria-label="Breadcrumbs"] a[data-slot="item"], nav[aria-label="Breadcrumbs"] span[data-slot="item"]{
  font-size: 12px !important;
}

/* #nav-bar header{
  padding-left: 0 !important;
  padding-right: 0 !important;
} */

#nav-bar{
  background: #f0feff !important;
}

span[data-slot="value"] {
  text-transform: capitalize;
}

.custom-check-box{
  align-items: start;
  gap: 10px;
}


.slide-box {
  overflow: hidden;
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
}

.hide-txt {
  position: absolute;
  top: 0%;
  transition: all .3s ease-out;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}
.hid-box {
  top: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  left: 0;
  width: 100%;
  position: absolute;
  transition: all .3s ease-out;
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.2);
  border-radius: 0.6125rem;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  height: 100%;
}

.slide-box:hover > .hid-box{
  top: 0;
}

.slide-box:hover > .hide-txt{
  top: -100%
}

.image-upload-container:hover > .upload-dp{
  display: flex
}

.image-upload-container > .upload-dp{
  display: none
}

span[data-slot="value"]{
  color: var(--secondary)
}

.active-link{
  font-weight: 600;
  color: var(--secondary);
}

.active-link::before{
  content: '';
  position: absolute;
  bottom: -10px;
  width: 30%;
  height: 3px;
  background: var(--secondary);
}

.truncated-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* You can adjust this based on your layout */
}

.no-child-border * {
  border: none;
}
.MuiTextField-root{
  min-width: 130px !important;
}

svg {
  cursor: pointer
}

#event-card-popup-menu{
  position: absolute;
  top: 0;
  right: 0;
}

.accordion-5 section{
justify-content: start !important;
}
.specialty .slick-track{
  display: flex !important;
  gap: 2rem;
}
.control-dots{
  width: fit-content !important;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 0px;
  margin: 0 !important;
  padding: 0 !important;
  position: absolute !important;
  left: 50%;
  transform: translateX(-50%);
}
.carousel .slide{
  height: calc(100vh - 250px) !important
}

.p-datepicker-calendar th, .p-datepicker-calendar th > div, .p-datepicker-calendar td{
  min-width: auto !important;
}

@media screen and (min-width: 1100px) and (max-width: 1300px) {
  table th, table th > div, table td {
    overflow: hidden;
    min-width: 120px;
    width: fit-content !important;
  }
}

@media screen and (min-width: 900px) and (max-width: 1099px) {
  table th, table th > div, table td {
    overflow: hidden;
    min-width: 140px;
    width: fit-content !important;
  }
}

@media screen and (max-width: 899px) {

  .MuiBox-root{
    height: 100px !important
  }
  .MuiBox-root > div{
    overflow: auto;
  }

  table th, table th > div, table td {
    overflow: hidden;
    min-width: 150px;
    width: fit-content !important;
  }
}
  .table-container, .table-container-full{
    position: relative;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .profile-mobile-end{
    width: 200px;
  }
.profile-mobile-end img {
  z-index: 1
}
.profile-card{
  z-index: 2
}
.p-datepicker-title{
  gap: 6px;
  display: flex;
}